import { render, staticRenderFns } from "./bindPhone.vue?vue&type=template&id=108c1286&scoped=true"
import script from "./bindPhone.vue?vue&type=script&lang=js"
export * from "./bindPhone.vue?vue&type=script&lang=js"
import style0 from "./bindPhone.vue?vue&type=style&index=0&id=108c1286&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "108c1286",
  null
  
)

export default component.exports