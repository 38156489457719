<template>
  <div class="register">
    <img src="../../../../assets/pc/images/login/loginLogo.png" alt="" />
    <!--    右侧头部区域-->
    <div class="login-right-top flex">
      <router-link to="normal">返回登录</router-link>
      <span>请先绑定手机号码</span>
    </div>
    <!--    右侧表单区域-->
    <div class="login-form">
      <el-form
        ref="verLoginFormRef"
        :model="verLoginForm"
        :rules="verLoginFormRules"
      >
        <el-form-item prop="name" style="display: none">
          <!-- <span>手机号</span> -->
          <!-- <el-input
            v-model="verLoginForm.name"
            placeholder="请输入联系人姓名"
            @keyup.native="restempty('verLoginForm', 'name')"
            maxlength="5"
          ></el-input> -->
        </el-form-item>
        <el-form-item prop="phoneNumber">
          <span>手机号</span>
          <el-input
            v-model="verLoginForm.phoneNumber"
            placeholder="请输入手机号码"
            maxlength="11"
            @keyup.native="restempty('verLoginForm', 'phoneNumber')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <div>短信验证码</div>
          <el-input
            v-model="verLoginForm.code"
            placeholder="请输入5位验证码"
            @keyup.native="restempty('verLoginForm', 'code')"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            maxlength="5"
          ></el-input>
          <el-button
            plain
            class="get-ver"
            @click="getVerfiCode"
            :disabled="disabled"
            >{{ button_label }}</el-button
          >
        </el-form-item>
        <el-form-item id="checkbox-form">
          <el-checkbox v-model="keepWake">我已阅读并同意</el-checkbox>
          <span style="color: #156ed0" @click="dialogVisible = true"
            >《政策服务平台服务及隐私协议》</span
          >
          <div
            style="color: #f56c6c; margin-top: 10px; font-size: 12px"
            v-if="keepWaketext"
          >
            请勾选同意服务及隐私协议
          </div>
          <div style="margin-top: 10px" v-else></div>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            class="submit-btn"
            @click="quickRegis"
            :class="{ 'check-box-click': !keepWake }"
            >立即绑定</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-dialog title="" :visible.sync="dialogVisible" width="80%">
      <div class="ql-bubble">
        <div v-html="agreementData" class="ql-editor"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { request } from "@/network";
import { getMemberId } from '@/assets/public/utils/token';
import {
  getnoreadmessage,
} from "@/assets/public/utils/token";
export default {
  name: "loginRightRegister",
  data() {
    return {
      keepWake: false,
      keepWaketext: false,
      verLoginForm: {
        phoneNumber: "",
        code: "",
      },
      verLoginFormRules: {
        phoneNumber: [
          {
            required: true,
            pattern: "^[1][3,4,5,6,7,8,9][0-9]{9}$",
            message: "请输入正确的11位手机号码",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      button_label: "获取验证码",
      disabled: false,
      isNotAgainAble: true,
      dialogVisible: false,
      agreementData: ''
    };
  },
  methods: {
    quickRegis() {
      let str = "";
      Object.values(this.$route.query).forEach((v) => {
        str += v;
      });
      this.$refs.verLoginFormRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await request({
            method: "GET",
            url: "/pcp/bindMobile",
            params: {
              mobile: this.verLoginForm.phoneNumber,
              code: this.verLoginForm.code,
              // openId: str,
              userId: getMemberId(),
              // sysType: this.$sysType,
            },
          });
          if (res.code === 1003)
            return this.$router.push({
              path: "/login/loginPhoneBind",
              query: res.data,
            });
          if (res.code === 501) return this.$message.error(res.message);
          if (res.code !== 200) return this.$message.error("绑定失败");
          this.$message.success("绑定成功");
          sessionStorage.setItem('isEnter', 'enter');
          this.$store.commit("login/setUser", [ res.data, true ]);
          this.$router.push("/index");
          this.getLoginInfo();
        }
      });
    },
    async getLoginInfo() {
      const { data: res } = await request({
        method: "POST",
        url: "/pcp/getLoginInfo",
      });
      if (res.code !== 200) return this.$message.error("获取用户信息失败");
      getnoreadmessage(request, res.data.id).then((resMessage) => {
        localStorage.setItem("notRead", resMessage);
        this.$store.commit("personal/setNotRead", resMessage);
        this.$store.commit("personal/setImgUrl", res.data.avatar);
      });
    },
    getVerfiCode() {
      // 阻止多次出现消息提示弹窗
      this.$message.closeAll();
      this.$refs.verLoginFormRef.validateField(
        "phoneNumber",
        async (validMes) => {
          if (validMes) return this.$message.error(validMes);
          this.disabled = true;
          const { data: res } = await request({
            method: "GET",
            url: "/pcp/verification",
            params: {
              phone: this.verLoginForm.phoneNumber,
              type: "register",
            },
          });
          if (res.code === 501)
            return this.$message.error(res.message), (this.disabled = false);
          if (res.code !== 200) return this.$message.error("验证码发送失败");
          this.$message.success("验证码已发送，请注意查收");
          this.countdown();
        }
      );
    },
    restempty(fval, value) {
      this[fval][value] = this[fval][value].replace(/[, ]/g, "");
    },
    countdown() {
      let time = 60;
      const timer = setInterval(() => {
        if (time === 0 || this.verLoginForm.phoneNumber.length < 11) {
          clearInterval(timer);
          this.disabled = false;
          this.button_label = "获取验证码";
        } else {
          this.button_label = time + "秒后重试";
          this.disabled = true;
          time--;
        }
      }, 1000);
    },
    async getAgreement () {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/index/getArticle",
        params: {
          sysType: 1,
          type: 1
        },
      });
      this.agreementData = res.data;
    }
  },
  watch: {
    keepWake(newVal) {
      if (newVal) this.keepWaketext = false;
    },
  },
  mounted () {
    this.getAgreement();
  }
};
</script>

<style scoped lang="less">
/*总体样式*/
.register {
  // height: 626px;
  width: 330px;
  margin-left: 139px;
  padding: 40px 0;
}
/*右侧头部样式*/
.login-right-top {
  margin-top: 40px;
}
.login-right-top span {
  color: rgba(16, 16, 16, 100);
  font-size: 24px;
  flex: 1;
  font-weight: 600;
}
.login-right-top a {
  margin-right: 48px;
  font-size: 14px;
  color: #156ed0;
  cursor: pointer;
}
.login-right-top div:before {
  content: "";
  display: inline-block;
  margin-right: 4px;
  width: 16px;
  height: 16px;
  background: url("~@/assets/pc/images/login/login-code.png");
}
/*右侧表单样式*/
.login-form {
  margin-top: 30px;
  padding-bottom: 30px;
}
.login-form {
  /deep/ .el-input_inner {
    -webkit-text-security: disc !important;
  }
}
.el-input {
  width: 100%;
  font-size: 16px;
  color: #000000;
}
.el-form-item:nth-child(n-1) {
  margin-bottom: 20px;
}
.el-form-item:nth-child(3) .el-input {
  width: 210px;
  margin-right: 10px;
}
.el-form-item:nth-child(3) {
  position: relative;
}
.el-form-item:nth-child(3) .el-button {
  margin-left: 0;
}
/*由于“获取验证码”按钮在强制设置高度之后样式会错位（在此处就是强行往下走了1.5px），所以得处理*/
.get-ver {
  position: absolute;
}
.el-form-item:nth-child(5) {
  margin-bottom: 0;
}
.el-form-item:nth-child(6) {
  margin-bottom: 27px;
}
.login-form /deep/ .el-input > input {
  height: 50px;
  padding-left: 12px;
  font-size: 16px;
  color: #8d8d8d;
}
.submit-btn {
  margin-bottom: 10px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #156ed0;
}
.check-box-click {
  background-color: #e5e5e5;
  border: none;
}
.submit-btn-continue {
  width: 100%;
  margin-left: 0;
}
.get-ver {
  width: 100px;
  height: 50px;
  padding: 12px 0;
  font-size: 14px;
  border: 1px solid #156ed0;
  color: #156ed0;
}
/* checkbox选中后颜色不变,让文字与选择框相距5px*/
#checkbox-form /deep/ .el-checkbox__label {
  color: #000000;
  padding-left: 5px;
  margin-top: 15px;
}
#checkbox-form a {
  color: #156ed0;
  font-size: 14px;
}
/*消除checkbox高度*/
#checkbox-form /deep/ .el-form-item__content {
  line-height: 0px;
}
/deep/ .el-form-item__content {
  line-height: 20px;
}
/deep/ .el-button.is-disabled.is-plain,
.el-button.is-disabled.is-plain:focus,
.el-button.is-disabled.is-plain:hover {
  border: 1px solid #156ed0;
  color: #156ed0;
  font-size: 14px;
}
</style>
